<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit" formName="registerForm" top-bar-action="login">
      <template #form>
        <Card class="p-fluid">
          <template #header>
            <h1 class="active">{{ $t('register.title') }}</h1>
          </template>
          <template #title></template>
          <template #content>
            <!--First Name-->
            <div class="p-fluid p-grid">
              <div class="p-col-6 p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    :placeholder="$t('register.first_name')"
                    v-model="user.firstName"
                    type="text"
                    name="firstname"
                    autofocus
                    :maxLength="getMaxLength('firstName')"
                  />
                </div>
                <field-error :value="v$.user.firstName" />
              </div>
              <!--LastName -->
              <div class="p-col-6 p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.lastName"
                    type="text"
                    name="lastName"
                    :placeholder="$t('register.last_name')"
                    :maxLength="getMaxLength('lastName')"
                  />
                </div>
                <field-error :value="v$.user.lastName" />
              </div>
            </div>
            <!--Email -->
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <InputText
                    v-model="user.email"
                    id="email"
                    type="email"
                    name="email"
                    :placeholder="$t('common.email')"
                    :maxLength="getMaxLength('email')"
                    @change="user.email = user.email.toLowerCase()"
                  />
                  <field-error :value="emailErrorMessage || v$.user.email" />
                </div>
              </div>
            </div>
            <!--Password -->
            <div class="p-fluid p-grid">
              <div class="p-col-6 p-pt-0 p-pb-1">
                <div class="p-field">
                  <PasswordComplexity
                    :placeholder="$t('common.password')"
                    v-model="user.password"
                    name="password"
                    toggleMask
                    autocomplete="password"
                  >
                  </PasswordComplexity>
                  <field-error :value="v$.user.password" />
                </div>
              </div>
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field">
                  <Password
                    :placeholder="$t('register.confirm_password')"
                    v-model="passwordConfirmation"
                    name="passwordConfirmation"
                    toggleMask
                    :feedback="false"
                    autocomplete="password-confirm"
                  />
                  <field-error :value="v$.passwordConfirmation" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col p-pt-0 p-pb-1">
                <div class="p-field-checkbox">
                  <Checkbox
                    id="chkAcceptConditions"
                    v-model="acceptTheConditions"
                    :binary="true"
                  ></Checkbox>
                  <label for="chkAcceptConditions"
                    >{{ $t('login.registering_disclosure_start') }}
                    <a
                      href="https://files.cybelesoft.com/docs/license-support-and-warranty-terms.pdf"
                      target="_blank"
                      >{{ $t('login.registering_terms_and_conditions') }}</a
                    >
                    {{ $t('login.registering_disclosure_end') }}</label
                  >
                </div>
              </div>
            </div>
            <div class="recaptchaContainer p-mt-1 p-mb-3">
              <vue-recaptcha
                v-if="showRecaptcha"
                :siteKey="reCaptchaSiteKey"
                theme="light"
                :size="compactCaptcha ? 'compact' : 'normal'"
                @verify="reCaptchaVerified"
                @expire="reCaptchaExpired"
                @fail="reCaptchaFailed"
                ref="vueRecaptcha"
              ></vue-recaptcha>
            </div>
          </template>
          <template #footer>
            <Button
              type="submit"
              :label="$t('register.register_button')"
              :disabled="inProcess || !confirmed"
            />
            <oauth-section action="register" legend="login.registerWith"/>
            <field-error
              class="form-errmsg p-mb-2 text-center"
              id="msgerror"
              :value="errorMessage"
            />
            <!-- <div class="p-mt-3 text-center small">{{ $t('login.already_user') }} <router-link to="./Login" tag="a">{{ $t('login.login_title') }}</router-link></div> -->
         </template>
        </Card>
      </template>
    </login-form>
  </div>

  <CommonDialog ref="dialog" style="max-width: 35rem"></CommonDialog>
</template>

<script>
import { authService } from '../services/auth.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required, sameAs, minLength, maxLength, and, requiredIf, helpers } from '@vuelidate/validators';
import CountriesHelper from '../helpers/CountriesHelper';
import { ErrorHelper } from '../helpers/ErrorHelper';
import LoginForm from '../components/LoginForm';
import PasswordComplexity from '../components/PasswordComplexity.vue';
import FieldError from '../components/FieldError.vue';
import vueRecaptcha from 'vue3-recaptcha2';
import { apiConfig } from '../config/backend-api';
import { SecurityHelper } from '../helpers/SecurityHelper';
import CommonDialog from '../components/CommonDialog.vue';
import OauthSection from '../components/OauthSection.vue';
import i18n from '../lang';

const forbiddenEmailProviders = typeof process.env.VUE_APP_SIGNUP_FORBIDDEN_EMAIL_PROVIDERS == 'string' ? JSON.parse(process.env.VUE_APP_SIGNUP_FORBIDDEN_EMAIL_PROVIDERS) : process.env.VUE_APP_SIGNUP_FORBIDDEN_EMAIL_PROVIDERS;
export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      showRecaptcha: false,
      reCaptchaSiteKey: '',
      errorMessage: '',
      emailErrorMessage: '',
      headerMessage: '',
      countries: null,
      countriesHelper: null,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',

        phone: '',
        country: '',
        state: '',
        companyName: '',
        reCaptchaToken: '',
      },
      passwordConfirmation: '',
      acceptTheConditions: false,
      inProcess: false,
      getMaxLength: (property) => {
        return SecurityHelper.getPropertyLength('register', property, true);
      },
      getLengthVal: (property) => {
        return SecurityHelper.getPropertyLength('register', property, false);
      }
    };
  },
  computed: {
    loginWelcome() {
      return ErrorHelper.getLinesFrom(this.$t('login.welcomeMessage'), '\n');
    },
    confirmed() {
      return this.user.reCaptchaToken !== '' && this.acceptTheConditions;
    },
    compactCaptcha() {
      return window.outerWidth < 500;
    },
  },
  validations() {
    const validPassword = (value) => this.$store.getters.checkPasswordComplexity(value);
    return {
      user: {
        firstName: {
          required,
          minLength: minLength(this.getLengthVal('firstName').minLength),
          maxLength: maxLength(this.getLengthVal('firstName').maxLength),
        },
        lastName: {
          required,
          minLength: minLength(this.getLengthVal('lastName').minLength),
          maxLength: maxLength(this.getLengthVal('lastName').maxLength),
        },
        email: { required, email },
        password: {
          required: helpers.withMessage(
            this.$t('passwordComplexity.validationError'),
            and(
              validPassword,
              requiredIf(function() {
                return this.password !== '';
              })
            )
          ),
        },
      },
      passwordConfirmation: {
        required: helpers.withMessage(
          this.$t('register.passwordMismatch'),
          sameAs(this.user.password)
        ),
        sameAsPassword: sameAs(this.user.password),
      },
      acceptTheConditions: { required },
    };
  },
  created() {
    this.countriesHelper = new CountriesHelper();
  },
  mounted() {
    this.reCaptchaSiteKey = apiConfig.reCaptchaSiteKey;
    this.showRecaptcha = !!this.reCaptchaSiteKey;
    this.countriesHelper.getCountries().then((data) => (this.countries = data));

    this.parseQueryErrorData();
  },
  components: {
    LoginForm,
    PasswordComplexity,
    FieldError,
    vueRecaptcha,
    CommonDialog,
    OauthSection
  },
  methods: {
    handleSubmit() {
      this.user.firstName = SecurityHelper.sanitizeString(this.user.firstName);
      this.user.lastName = SecurityHelper.sanitizeString(this.user.lastName);

      this.errorMessage = '';
      this.emailErrorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.reCaptchaInvalidate();
        return;
      }

      const provider = this.user.email.split('@')[1];
      const _hasForbiddenProvider = forbiddenEmailProviders.some(t => provider?.startsWith(t));

      if (_hasForbiddenProvider) {
        this.emailErrorMessage = i18n.global.t("register.requiresCorporateEmailError");
        this.reCaptchaInvalidate();
        return;
      }

      this.inProcess = true;
      authService
        .register(this.user)
        .then(() => {
          this.$refs.dialog
            .show({
              title: this.$t('register.popup_ok_title'),
              message: this.$t('register.successfullyMessage').split('#'),
              icon: 'thin-check',
            })
            .then(() => {
              this.$router.push('/login');
            });
        })
        .catch((error) => {
          this.handleRegisterError(error);
        });
    },
    handleRegisterError(error, { captcha = true } = {}) {
      this.inProcess = false;
      if (captcha) this.reCaptchaInvalidate();
      this.errorMessage = ErrorHelper.getErrorMessage(error);
    },
    parseQueryErrorData() {
      let { errmsg, ...query } = this.$route.query;
      errmsg = errmsg ? errmsg.replaceAll(' ', '_') : errmsg;

      let errorData;
      try {
        errorData = errmsg ? JSON.parse(Buffer.from(errmsg, 'base64')) : null;
      } catch(err) { /**/ }

      if (errorData) {
        this.handleRegisterError(errorData, { captcha: false });
      }

      //clear used query params
      this.$router.replace({ query: { ...query } });
    },
    linesFrom(value, splitter) {
      return ErrorHelper.getLinesFrom(value, splitter);
    },
    reCaptchaVerified(token) {
      this.user.reCaptchaToken = token;
    },
    reCaptchaInvalidate() {
      this.$refs.vueRecaptcha.reset();
      this.user.reCaptchaToken = '';
    },
    reCaptchaExpired() {
      this.reCaptchaInvalidate();
    },
    reCaptchaFailed() {
      this.reCaptchaInvalidate();
    },
  },
};
</script>
